<template>
    <div class="app-container">
        <div class="filter-container">
            <el-radio-group v-model="listQuery.type" class="filter-item" size="small" @change="handleFilter">
                <el-radio-button :label="1">外卖</el-radio-button>
                <el-radio-button :label="2">商城</el-radio-button>
            </el-radio-group>
        </div>

        <div class="filter-container">
            <el-button class="filter-item" type="primary" size="small" @click="handleCreate">新增</el-button>

            <el-select v-model="listQuery.is_on" placeholder="状态" style="width: 120px" class="filter-item" size="small" clearable>
                <el-option label="下架" :value="0" />
                <el-option label="正常" :value="1" />
            </el-select>

            <el-button class="filter-item" type="primary" size="small" @click="handleFilter">搜索</el-button>
        </div>

        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column align="center" label="ID" width="80">
                <template slot-scope="scope">
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="学校" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.school?scope.row.school.school_name:'' }}
                </template>
            </el-table-column>

            <el-table-column label="类型" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.type === 1" type="primary">外卖</el-tag>
                    <el-tag v-else-if="scope.row.type === 2" type="warning">商城</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="开始时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.start_time }}
                </template>
            </el-table-column>

            <el-table-column label="结束时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.end_time }}
                </template>
            </el-table-column>

            <el-table-column label="状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.is_on === 1" type="success">正常</el-tag>
                    <el-tag v-else-if="scope.row.is_on === 0" type="danger">下架</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">编辑</el-button>

                    <el-button type="danger" size="mini" @click="handleDel(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <!-- 详情 -->
        <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="50%" :close-on-click-modal="false">
            <el-form ref="form" :rules="rules" :model="form" label-position="left" label-width="150px">
                <el-form-item label="类型" prop="type">
                    <el-radio-group v-model="form.type" disabled>
                        <el-radio-button :label="1">外卖</el-radio-button>
                        <el-radio-button :label="2">商城</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="开始时间" prop="start_time">
                    <el-time-picker v-model="form.start_time" format="HH:mm:ss" value-format="HH:mm:ss" placeholder="选择时间"></el-time-picker>
                </el-form-item>

                <el-form-item label="结束时间" prop="end_time">
                    <el-time-picker v-model="form.end_time" format="HH:mm:ss" value-format="HH:mm:ss" placeholder="选择时间"></el-time-picker>
                </el-form-item>

                <el-form-item label="状态" prop="is_on">
                    <el-switch v-model="form.is_on" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0">
                    </el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
            </div>
        </el-dialog>
        <!-- 详情 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    type: 1,
                    is_on: '',
                },
                dialogFormVisible: false,
                dialogStatus: '',
                textMap: {
                    update: '修改',
                    create: '新增',
                },
                btnLoading: false,
                form:{
                    id: '',
                    school_id: '',
                    type: '',
                    start_time: '',
                    end_time: '',
                    is_on: 1,
                },
                rules: {
                    type: [{ required: true, message: '请选择类型', trigger: 'change' }],
                    start_time: [{ required: true, message: '请选择开始时间', trigger: 'change' }],
                    end_time: [{ required: true, message: '请选择结束时间', trigger: 'change' }],
                    is_on: [{ required: true, message: '请选择状态', trigger: 'change' }],
                },
            };
        },
        created() {
            this.getList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"]),
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/flashSaleTime/list",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            resetForm() {
                this.form = {
                    id: '',
                    school_id: '',
                    type: this.listQuery.type || '',
                    start_time: '',
                    end_time: '',
                    is_on: 1,
                }
                this.btnLoading = false
            },
            handleCreate() {
                this.resetForm()
                this.dialogStatus = 'create'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            handleUpdate(row) {
                this.resetForm()
                this.form = Object.assign({}, row)
                this.dialogStatus = 'update'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            saveData() {
                if (!this.school_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择学校"
                    });
                    return;
                } else {
                    this.form.school_id = this.school_id;
                }

                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.btnLoading = true
                        request({
                            url: '/api/backend/flashSaleTime/save',
                            method: 'post',
                            data: this.form
                        }).then(() => {
                            this.btnLoading = false
                            this.dialogFormVisible = false
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getList();
                        }).catch(() => {
                            this.btnLoading = false
                        })
                    }
                })
            },
            handleDel(val) {
                this.$confirm('确认继续该操作？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    request({
                        url: '/api/backend/flashSaleTime/del',
                        method: 'post',
                        data: {id: val}
                    }).then(()=>{
                        // 删除最后一条数据时无数据问题
                        this.list.length <= 1 ? this.listQuery.page-- : false;
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                    })
                }).catch(() => {

                });
            },
        }
    };
</script>


<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
